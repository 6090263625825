import { Box, capitalize, SxProps, Theme, Typography } from "@mui/material"
import React from "react"
import { useAppContext } from "../app/Context"

interface AgentNameHeaderProps {
  sx?: SxProps<Theme>
}

export default function AgentNameHeader({ sx }: AgentNameHeaderProps) {
  const agent = useAppContext()?.agent

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={"#FFFFFF66"}
      p={1}
      borderRadius={1}
      flexGrow={1}
      sx={sx}
    >
      <Typography variant="body2" fontWeight={"bold"}>
        Chat with {capitalize(agent?.name || "Agent")}
      </Typography>
    </Box>
  )
}
