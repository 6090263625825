import { Stack } from "@mui/material"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import React, { Fragment, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import AgentNotFoundImage from "../../assets/Agent404.svg"
import Background from "../../components/background"
import Feedback from "../../components/Feedback/Feedback"
import FeedbackSubmitted from "../../components/Feedback/FeedbackSubmitted"
import { Header } from "../../components/header"
import { MainButton } from "../../components/mainButton"
import { SnackbarWithText } from "../../components/snackbar"
import { logAnalyticsEvent } from "../../external/analytics"
import { endCallDb } from "../../external/firestore"
import { AnalyticsEvents } from "../../types/Analytics"
import { StateAction } from "../../types/State"
import { SnackbarType } from "../../types/UI"
import {
  getAgent,
  getAgentUsage,
  getCall,
  getDefaultAgentId,
} from "../../utils/general"
import { useAppContext, useAppDispatchContext } from "../Context"

export function PostScreen() {
  const { id } = useParams()
  const context = useAppContext()
  const dispatch = useAppDispatchContext()
  const email = context.user.email

  const navigate = useNavigate()
  const { state } = useLocation()
  const error = state?.error || false

  const [summaryOpen, setSummaryOpen] = useState(false)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [headline, setHeadline] = useState("You left the meeting")
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)

  useEffect(() => {
    const fetchCallAndAgent = async () => {
      if (error) return

      try {
        const call = await getCall(id)
        if (call && call.agentId && call.agentId !== getDefaultAgentId) {
          const [agent, agentUsage] = await Promise.all([
            getAgent(call.agentId),
            getAgentUsage(call.agentId),
          ])
          dispatch({ type: StateAction.setAgent, payload: agent })
          dispatch({ type: StateAction.setAgentUsage, payload: agentUsage })
        } else if (call && call.agentId === getDefaultAgentId) {
          dispatch({
            type: StateAction.setAgentId,
            payload: getDefaultAgentId,
          })
        }
      } catch (error) {
        console.error("Error fetching call and agent:", error)
      }
    }
    fetchCallAndAgent()
  }, [id, dispatch])

  const handleEndCallAndSummary = async () => {
    await endCallDb(id)
    setSummaryOpen(false)
    console.log("A summary of the meeting was sent to ", email)
    setButtonsDisabled(true)
    setHeadline("Your meeting has ended")
    logAnalyticsEvent(AnalyticsEvents.SummaryRequested)
  }

  const rejoin = async () => {
    logAnalyticsEvent(AnalyticsEvents.Rejoined)
    navigate(`/rooms/${id}`)
  }

  const handleClose = () => {
    setFeedbackOpen(false)
    setSummaryOpen(false)
  }

  return (
    <>
      <Header />
      <Stack
        direction={"column"}
        spacing={4}
        sx={{
          height: "85vh",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          position: "relative",
        }}
      >
        {error && <img src={AgentNotFoundImage} alt="Agent not found" />}
        <Typography variant="h4">
          {error ? "Something went wrong!" : headline}
        </Typography>
        {error && (
          <Stack direction={"column"} spacing={1}>
            <Typography variant="body2">
              Please contact support if this issue persists.
            </Typography>
            <a href="mailto:support@beyondpresence.ai">
              <Typography variant="body2">support@beyondpresence.ai</Typography>
            </a>
          </Stack>
        )}
        {!error && (
          <Fragment>
            <MainButton
              sx={{ maxWidth: "300px" }}
              onClick={rejoin}
              disabled={buttonsDisabled}
              text="Rejoin"
            />
            <Button
              variant="text"
              onClick={handleEndCallAndSummary}
              disabled={buttonsDisabled}
              sx={{ textTransform: "none", color: "#40069E" }}
            >
              End the call!
            </Button>
          </Fragment>
        )}
        <SnackbarWithText
          open={summaryOpen}
          severity={SnackbarType.success}
          text={`A summary of the meeting was sent to ${email}`}
          handleClose={handleClose}
        />
        <br></br>
        {!error && (
          <>
            {feedbackSubmitted ? (
              <FeedbackSubmitted />
            ) : (
              <Feedback
                visible={feedbackOpen}
                onClose={() => setFeedbackOpen(false)}
                onOpen={() => setFeedbackOpen(true)}
                onSubmit={() => setFeedbackSubmitted(true)}
              />
            )}
          </>
        )}
        <Background />
      </Stack>
    </>
  )
}
