import { Close } from "@mui/icons-material"
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { Smiley, SmileyMeh, SmileySad, SmileyWink } from "@phosphor-icons/react"
import React, { useState } from "react"
import { useAppContext } from "../../app/Context"
import { logAnalyticsEvent } from "../../external/analytics"
import { AnalyticsEvents } from "../../types/Analytics"
import { FeedbackEntity, Feedback as TFeedback } from "../../types/Feedback"
import { createFeedback } from "../../utils/general"
import { MainButton } from "../mainButton"

const feedbackTopics = [
  "Audio quality",
  "Avatar quality",
  "Responses",
  "Interface",
  "Chat",
] as const
type FeedbackTopic = (typeof feedbackTopics)[number]

interface FeedbackProps {
  visible: boolean
  onClose: () => void
  onOpen: () => void
  onSubmit: () => void
}

export default function Feedback({
  visible,
  onClose,
  onOpen,
  onSubmit,
}: FeedbackProps) {
  const [rating, setRating] = useState<number | null>(null)
  const [feedback, setFeedback] = useState<string>("")
  const [contactConsent, setContactConsent] = useState<boolean>(false)
  const [feedbackTopic, setFeedbackTopic] = useState<FeedbackTopic>(null)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const context = useAppContext()

  const handleSubmitFeedback = async () => {
    try {
      setIsSubmitting(true)
      const feedbackPayload: Partial<TFeedback> = {
        feedback,
        rating,
        feedbackTopic,
        contactConsent,
        entityType: FeedbackEntity.CALL,
        entityId: context.call.id,
        creatorId: context.user.uid,
      }
      await createFeedback(feedbackPayload)
      logAnalyticsEvent(AnalyticsEvents.Feedback, feedbackPayload)
    } catch (error) {
      console.warn("Error submitting feedback", error)
      setError("Something went wrong. Please try again.")
    }
    setIsSubmitting(false)
  }

  const handleClearFeedback = () => {
    setFeedback("")
    setRating(null)
    setContactConsent(false)
    setFeedbackTopic(feedbackTopics[0])
  }

  const handleSubmit = async () => {
    if (!rating) {
      setError("Please rate the product")
      return
    }
    setError(null)
    await handleSubmitFeedback()
    handleClearFeedback()
    onClose()
    onSubmit()
  }

  const handlePressChip = (feedbackTopicArg: FeedbackTopic) => {
    setFeedbackTopic(feedbackTopicArg)
  }

  const handlePressOption = (ratingArg: number) => {
    setRating(ratingArg)
    onOpen()
  }

  return (
    <Box sx={visible ? styles.modalOverlayActive : styles.modalOverlay}>
      <Box sx={styles.modalContent}>
        {visible && (
          <IconButton onClick={onClose} sx={styles.closeButton}>
            <Close />
          </IconButton>
        )}
        <Typography variant="h6" sx={styles.modalTitle}>
          {"How happy are you with your call experience?"}
        </Typography>
        {error && (
          <Typography variant="body2" color={"#F00000"}>
            {error}
          </Typography>
        )}
        <Box sx={styles.feedbackOptions}>
          <FeedbackOption
            Icon={SmileySad}
            text="Not what I expected"
            onClick={() => handlePressOption(1)}
            selected={rating === 1}
          />
          <FeedbackOption
            Icon={SmileyMeh}
            text="Fine, but not perfect"
            onClick={() => handlePressOption(2)}
            selected={rating === 2}
          />
          <FeedbackOption
            Icon={Smiley}
            text="Meets my expectation"
            onClick={() => handlePressOption(3)}
            selected={rating === 3}
          />
          <FeedbackOption
            Icon={SmileyWink}
            text="Happy with the result!"
            onClick={() => handlePressOption(4)}
            selected={rating === 4}
          />
        </Box>

        {visible && (
          <>
            <Box sx={styles.feedbackForm}>
              <Typography variant="subtitle1" sx={styles.feedbackPrompt}>
                Please help us improve! What can be done better?
              </Typography>
              <Box sx={styles.chipContainer}>
                {feedbackTopics.map((item) => (
                  <Chip
                    key={item}
                    label={item}
                    onClick={() => handlePressChip(item)}
                    sx={[
                      styles.chip,
                      feedbackTopic === item && styles.chipSelected,
                    ]}
                    variant={feedbackTopic === item ? "outlined" : "filled"}
                  />
                ))}
              </Box>
              <TextField
                multiline
                rows={4}
                placeholder="Your comment"
                fullWidth
                variant="outlined"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                sx={styles.textField}
              />
            </Box>
            <Box sx={styles.checkboxContainer}>
              <Checkbox
                checked={contactConsent}
                onChange={(e) => setContactConsent(e.target.checked)}
              />
              <Typography variant="body2">
                You can contact me about this feedback
              </Typography>
            </Box>
          </>
        )}
        <Box sx={styles.submitButtonContainer}>
          <MainButton
            sx={{ maxWidth: "300px" }}
            text={isSubmitting ? "Submitting..." : "Submit Feedback"}
            disabled={isSubmitting}
            onClick={visible ? handleSubmit : onOpen}
            small
          />
        </Box>
      </Box>
    </Box>
  )
}

type FeedbackOptionProps = {
  Icon: React.ElementType
  text: string
  onClick: () => void
  selected: boolean
}

const FeedbackOption = ({
  Icon,
  text,
  onClick,
  selected,
}: FeedbackOptionProps) => {
  return (
    <Box
      sx={{
        width: "23%",
        aspectRatio: "1/1",
        borderRadius: "1rem",
        backgroundColor: selected ? "#ECE6FF" : "#F5F5F7",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        border: selected ? `2px solid #4019B6` : "none",
        "&:hover": {
          border: `2px solid #4019B6`,
        },
        padding: { xs: "0.5rem", sm: "0.75rem" },
      }}
      onClick={onClick}
    >
      <Icon
        size={"1rem"}
        style={{ width: "4rem", height: "4rem", color: "#6301fe" }}
      />
      <Typography
        variant="body2"
        sx={{
          color: "#220738",
          fontWeight: "bold",
          mt: "0.25rem",
          textAlign: "center",
          fontSize: "0.75rem",
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

const styles = {
  modalOverlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  modalOverlayActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "fixed",
    bottom: 5,
    left: 5,
    right: 5,
    zIndex: 10000,
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "1rem",
    padding: "1rem",
    position: "relative",
    width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
    maxWidth: "600px",
    backgroundColor: "background.paper",
    minHeight: "40vh",
  },
  closeButton: {
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem",
  },
  modalTitle: {
    fontWeight: "bold",
    textAlign: "center",
    width: "90%",
  },
  feedbackOptions: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: { xs: "row", sm: "row" },
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "1rem 0",
  },
  feedbackForm: {
    width: "100%",
  },
  feedbackPrompt: {
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "0.5rem",
    marginBottom: "1rem",
  },
  chip: {
    backgroundColor: "action.hover",
    "&:hover": {
      backgroundColor: "action.selected",
    },
  },
  chipSelected: {
    backgroundColor: "#ECE6FF",
  },
  textField: {
    marginBottom: "1rem",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  submitButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}
