import React, { useState } from "react"

import AspectRatio from "@mui/joy/AspectRatio"

import { Box, useMediaQuery, useTheme } from "@mui/material"
import { MainButton } from "../../components/mainButton"
import { SnackbarWithText } from "../../components/snackbar"
import { UserVideo } from "../../components/UserVideo/UserVideo"
import { ControlVideoWrapper } from "../../components/VideoWrappers/ControlVideoWrapper"
import { getMedia } from "../../lib/getMedia"
import { StateAction } from "../../types/State"
import { SnackbarType } from "../../types/UI"
import { useAppContext, useAppDispatchContext } from "../Context"

interface VideoOrPlaceholderProps {
  setPermissionsGranted: (granted: boolean) => void
  disableButton: boolean
}

export function VideoOrPlaceholder({
  setPermissionsGranted,
  disableButton,
}: VideoOrPlaceholderProps) {
  const context = useAppContext()
  const dispatch = useAppDispatchContext()
  const stream = context.stream
  const [errorOpen, setErrorOpen] = useState(false)
  const theme = useTheme()
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"))

  const handleAllowMicrophoneAndCamera = async () => {
    const stream = await getMedia(null, () => setErrorOpen(true), context)
    console.log("stream", stream)
    if (stream != null) {
      dispatch({ type: StateAction.setStream, payload: stream })
      setPermissionsGranted(true)
    }
  }

  const disabled =
    stream != null && stream.getTracks != null && stream.getTracks().length > 0

  const content = (
    <AspectRatio
      ratio={isMobileOrTablet ? "4/3" : "16/9"}
      sx={{
        width: "100%",
        maxWidth: isMobileOrTablet ? "600px" : 600,
        borderRadius: "12px",
        bgcolor: "transparent",
        minHeight: isMobileOrTablet ? 300 : undefined,
        display: "flex",
        overflow: "visible",
        mx: "auto",
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          backgroundColor: "#10072B",
          boxShadow: "0px 4px 24px 0px rgba(26, 10, 70, 0.30)",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {disabled ? (
          <UserVideo />
        ) : disableButton ? (
          <MainButton
            onClick={() => {}}
            disabled={false}
            text="Allow microphone and camera"
            small={isMobileOrTablet}
            sx={{
              backgroundColor: "#FFFFFF",
              "&:hover": { backgroundColor: "#FFFFFF" },
              border: "1px solid #DCD9E2",
              color: "#DCD9E2",
              mx: "20px",
              maxWidth: "300px",
              cursor: "not-allowed",
            }}
          />
        ) : (
          <MainButton
            onClick={handleAllowMicrophoneAndCamera}
            disabled={disabled}
            text="Allow microphone and camera"
            small={isMobileOrTablet}
            sx={{
              backgroundColor: "#DCD9E2",
              "&:hover": { backgroundColor: "#DCD9E2" },
              color: "#40069E",
              mx: "20px",
              maxWidth: "300px",
            }}
          />
        )}
      </Box>
    </AspectRatio>
  )

  return (
    <>
      <ControlVideoWrapper>{content}</ControlVideoWrapper>
      <SnackbarWithText
        open={errorOpen}
        severity={SnackbarType.error}
        text="Please go to your settings and allow access to your camera and microphone!"
        handleClose={() => setErrorOpen(false)}
      />
    </>
  )
}
