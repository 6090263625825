import { AgentLanguages } from "../types/Agent"
import { Action, State, StateAction } from "../types/State"

const initialCall = {
  chatMessages: [],
  id: "",
  startedAt: new Date(),
}

const initialAgent = {
  id: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  name: "Felix AI",
  language: AgentLanguages.English,
}

export const initialState: State = {
  user: null,
  audioEnabled: false,
  videoEnabled: false,
  audioDevice: null,
  videoDevice: null,
  speakerDevice: null,
  stream: null,
  peerConnection: null,
  dataConnection: null,
  videoSource: null,
  call: initialCall,
  agent: initialAgent,
  agentUsage: null,
}

export const appReducer = (state: State, action: Action): State => {
  let audioEnabled
  let videoEnabled
  let stream
  switch (action.type) {
    case StateAction.setUser:
      return { ...state, user: action.payload }
    case StateAction.end:
      return {
        ...state,
        audioEnabled: false,
        videoEnabled: false,
        stream: null,
      }
    case StateAction.toggleAudio:
      stream = state.stream
      audioEnabled = state.audioEnabled
      if (stream != null && stream.getAudioTracks != null) {
        audioEnabled = !audioEnabled
        stream.getAudioTracks()[0].enabled = audioEnabled
      }
      return { ...state, audioEnabled: audioEnabled, stream: stream }
    case StateAction.toggleVideo:
      stream = state.stream
      videoEnabled = state.videoEnabled
      if (stream != null && stream.getAudioTracks != null) {
        videoEnabled = !videoEnabled
        stream.getVideoTracks()[0].enabled = videoEnabled
      }
      return { ...state, videoEnabled: videoEnabled, stream: stream }
    case StateAction.setAudioDevice:
      return { ...state, audioDevice: action.payload }
    case StateAction.setVideoDevice:
      return { ...state, videoDevice: action.payload }
    case StateAction.setSpeakerDevice:
      return { ...state, speakerDevice: action.payload }
    case StateAction.setStream:
      return {
        ...state,
        stream: action.payload,
        audioEnabled: true,
        videoEnabled: true,
      }
    case StateAction.setPeerConnection:
      return { ...state, peerConnection: action.payload }
    case StateAction.setDataConnection:
      return { ...state, dataConnection: action.payload }
    case StateAction.setVideoSource:
      return { ...state, videoSource: action.payload }
    case StateAction.setChatHistory:
      return { ...state, call: { ...state.call, chatMessages: action.payload } }
    case StateAction.setCall:
      return { ...state, call: action.payload }
    case StateAction.addChatMessage:
      return {
        ...state,
        call: {
          ...state.call,
          chatMessages: [...state.call.chatMessages, action.payload],
        },
      }
    case StateAction.unhideChatMessages:
      return {
        ...state,
        call: {
          ...state.call,
          chatMessages: state.call.chatMessages.map((msg) => ({
            ...msg,
            hidden: false,
          })),
        },
      }
    case StateAction.setAgentId:
      return {
        ...state,
        agent: {
          ...state.agent,
          id: action.payload,
        },
      }
    case StateAction.setAgentUsage:
      return { ...state, agentUsage: action.payload }
    case StateAction.setAgent:
      return { ...state, agent: action.payload }
    case StateAction.resetState:
      return initialState
    default:
      console.log("Invalid action type", JSON.stringify(action))
      throw new Error("Invalid action type")
  }
}
