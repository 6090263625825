import * as Sentry from "@sentry/react"
import Bowser from "bowser"
import { UsageDetails } from "../types/UsageDetails"
import { getCall } from "./general"

export const verifyCallExists = async (callId: string): Promise<boolean> => {
  try {
    const call = await getCall(callId)
    return call != null
  } catch (error) {
    console.error("Error verifying call:", error)
    return false
  }
}

export const getAvailableCredits = (usageDetails: UsageDetails): number => {
  const usedCredits = usageDetails.current_usage / 1000
  const totalCredits = usageDetails.usage_limit / 1000
  const remainingCredits = totalCredits - usedCredits
  return Math.max(remainingCredits, 0)
}

export const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`
}

export const getBrowserDetails = () => {
  const userAgent = navigator.userAgent || (window as any).opera
  const browser = Bowser.getParser(userAgent)

  const socialMediaDetection = {
    hasLinkedIn: userAgent.includes("LinkedInApp"),
    hasFacebook: userAgent.includes("FBAN") || userAgent.includes("FBAV"),
    hasInstagram: userAgent.includes("Instagram"),
    hasTwitter: userAgent.includes("Twitter"),
    hasReddit: userAgent.includes("reddit"),
    hasLine: userAgent.includes("Line"),
    hasWeChat: userAgent.includes("MicroMessenger"),
  }

  return {
    userAgent,
    browserName: browser.getBrowserName(),
    browserVersion: browser.getBrowserVersion(),
    osName: browser.getOSName(),
    osVersion: browser.getOSVersion(),
    platformType: browser.getPlatformType(),
    engineName: browser.getEngineName(),
    socialMediaDetection,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    devicePixelRatio: window.devicePixelRatio,
  }
}

export const isInAppBrowser = () => {
  try {
    const { userAgent, socialMediaDetection } = getBrowserDetails()

    if (Object.values(socialMediaDetection).some((value) => value)) {
      return true
    }

    const browser = Bowser.getParser(userAgent)
    const browserName = browser.getBrowserName().toLowerCase()
    const platformType = browser.getPlatformType()

    if (platformType === "desktop") {
      return false
    }

    if (
      browserName === "safari" ||
      browserName === "chrome" ||
      browserName === "firefox"
    ) {
      return false
    }

    return true
  } catch (error) {
    Sentry.withScope((scope) => {
      const details = getBrowserDetails()
      scope.setContext("browser_details", details)
      scope.setExtra("error", error)
      scope.setLevel("warning")
      Sentry.captureException(new Error("Error in isInAppBrowser detection"))
    })
    return false
  }
}

export const getDeviceType = (): "android" | "ios" | "unknown" => {
  const parser = Bowser.getParser(window.navigator.userAgent)
  const os = parser.getOS()

  switch (os.name?.toLowerCase()) {
    case "android":
      return "android"
    case "ios":
      return "ios"
    default:
      return "unknown"
  }
}

export const openInExternalBrowser = (currentUrl: string) => {
  const deviceType = getDeviceType()
  const isInApp = isInAppBrowser()

  const urlWithRedirect = new URL(currentUrl)
  if (isInApp) {
    urlWithRedirect.searchParams.set("redirected", "true")
  }
  const baseUrl = urlWithRedirect.toString()

  switch (deviceType) {
    case "android":
      window.location.href = `intent:${baseUrl}#Intent;scheme=https;package=com.android.chrome;end`

      setTimeout(() => {
        window.location.href = `intent:${baseUrl}#Intent;end`
      }, 2000)
      break

    case "ios":
      window.location.href = `x-safari-${baseUrl}`
      break

    default:
      window.open(baseUrl, "_system")
  }
}

export const decodeMessage = (message: string): string => {
  try {
    let processedMessage = message.replace(/^["'](.*)["']$/, "$1")

    // Check if the message is already decoded by attempting to encode it
    const encoded = JSON.stringify(processedMessage)
    const decoded = JSON.parse(encoded)

    if (processedMessage.includes("\\u")) {
      return decoded.replace(/\\u[\dA-F]{4}/gi, (match) =>
        String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16)),
      )
    }

    return processedMessage
  } catch (error) {
    return message.replace(/^["'](.*)["']$/, "$1")
  }
}
