import { Player } from "@lottiefiles/react-lottie-player"
import { Box, Typography } from "@mui/material"
import React from "react"
import CallLoadingAnimation from "../../assets/call-loading.json"

export default function CallLoading() {
  return (
    <Box
      height={"100%"}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"relative"}
      sx={{
        background:
          "linear-gradient(to right, #EAFBA4, #F5F8DD,#EBD7EF,#DDBFF6)",
      }}
    >
      <Player autoplay loop src={CallLoadingAnimation} />
      <Typography color={"#220738"} variant="body2" fontWeight={500}>
        It may take up to 20 seconds to join the call due to high traffic.
      </Typography>
      <Box position={"absolute"} top={"1rem"} left={"1rem"}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <path
            d="M38.3511 17.4086C39.2402 17.0704 39.6996 16.0638 39.2043 15.2517C38.5685 14.2092 37.7316 13.2964 36.7366 12.5696C35.2755 11.5023 33.5361 10.8818 31.7294 10.7834C29.9227 10.685 28.1263 11.1128 26.5579 12.0151C25.7514 12.479 25.023 13.0582 24.3938 13.7304L24.3604 13.7114C24.3604 13.7114 19.2469 19.1198 16.137 21.9361C14.9245 23.0341 13.1251 24.6039 12.5164 25.1336C12.223 25.3177 11.9128 25.4766 11.5888 25.6075C10.5292 26.0357 9.36816 26.1474 8.2464 25.9289C7.12464 25.7104 6.09036 25.1711 5.26898 24.3765C4.86314 23.9838 4.51789 23.5372 4.24161 23.0514C3.77134 22.2246 2.86728 21.6245 1.94011 21.8372C1.01295 22.0498 0.419529 22.9837 0.79843 23.8562C1.28483 24.9763 1.98821 25.9955 2.87382 26.8523C4.17426 28.1103 5.81176 28.9641 7.58777 29.3101C9.36378 29.656 11.202 29.4793 12.8796 28.8013C13.9426 28.3717 14.9121 27.7525 15.7437 26.9792L15.799 27.0059L27.4693 15.5587C27.7211 15.3517 27.9906 15.165 28.2756 15.001C29.2662 14.4311 30.4009 14.1609 31.5421 14.223C32.6832 14.2852 33.7818 14.6771 34.7047 15.3512C35.1607 15.6843 35.5641 16.0792 35.9045 16.5224C36.484 17.2767 37.462 17.7468 38.3511 17.4086Z"
            fill="#4019B6"
          />
          <path
            d="M11.9213 11.1352C12.8366 11.3942 13.184 12.4447 12.7618 13.2971C12.3395 14.1495 11.3027 14.4696 10.3649 14.3103C10.0258 14.2526 9.68122 14.2246 9.33504 14.227C8.41187 14.2333 7.50305 14.456 6.68151 14.8771C5.85997 15.2982 5.14858 15.906 4.60443 16.6518C4.40038 16.9315 4.22197 17.2276 4.07075 17.5366C3.65256 18.391 2.78737 19.0458 1.84882 18.8909C0.910272 18.736 0.260201 17.8407 0.584372 16.9464C0.88408 16.1196 1.29992 15.3365 1.82167 14.6214C2.68318 13.4407 3.80948 12.4783 5.11018 11.8116C6.41087 11.1449 7.84975 10.7923 9.31133 10.7823C10.1965 10.7762 11.0751 10.8958 11.9213 11.1352Z"
            fill="#4019B6"
          />
          <path
            d="M17.8256 15.6265C17.8256 16.6843 16.9681 17.5418 15.9104 17.5418C14.8526 17.5418 13.9951 16.6843 13.9951 15.6265C13.9951 14.5687 14.8526 13.7112 15.9104 13.7112C16.9681 13.7112 17.8256 14.5687 17.8256 15.6265Z"
            fill="#4019B6"
          />
          <path
            d="M28.0532 29.1733C27.1379 28.9143 26.7905 27.8638 27.2127 27.0114C27.635 26.159 28.6718 25.8389 29.6096 25.9982C29.9487 26.0559 30.2933 26.0839 30.6395 26.0815C31.5626 26.0752 32.4714 25.8525 33.293 25.4314C34.1145 25.0103 34.8259 24.4025 35.3701 23.6567C35.5741 23.377 35.7525 23.0809 35.9037 22.7719C36.3219 21.9175 37.1871 21.2627 38.1257 21.4176C39.0642 21.5725 39.7143 22.4678 39.3901 23.3621C39.0904 24.1889 38.6746 24.972 38.1528 25.6871C37.2913 26.8678 36.165 27.8302 34.8643 28.4969C33.5636 29.1636 32.1247 29.5162 30.6632 29.5262C29.778 29.5323 28.8994 29.4127 28.0532 29.1733Z"
            fill="#4019B6"
          />
          <path
            d="M22.1491 24.683C22.1491 23.6252 23.0066 22.7677 24.0644 22.7677C25.1221 22.7677 25.9796 23.6252 25.9796 24.683C25.9796 25.7408 25.1221 26.5983 24.0644 26.5983C23.0066 26.5983 22.1491 25.7408 22.1491 24.683Z"
            fill="#4019B6"
          />
        </svg>
      </Box>
    </Box>
  )
}
