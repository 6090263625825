import { Box } from "@mui/material"
import React from "react"

export const AppLogo: React.FC = () => {
  return (
    <Box position="fixed" top={"4.75rem"} left={"2.25rem"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <g opacity="0.6">
          <path
            d="M38.3514 17.4086C39.2405 17.0704 39.6999 16.0638 39.2046 15.2517C38.5688 14.2092 37.7319 13.2964 36.7369 12.5696C35.2758 11.5023 33.5365 10.8818 31.7297 10.7834C29.923 10.685 28.1266 11.1128 26.5582 12.0151C25.7517 12.479 25.0233 13.0582 24.3941 13.7304L24.3607 13.7114C24.3607 13.7114 19.2472 19.1198 16.1373 21.9361C14.9248 23.0341 13.1254 24.6039 12.5167 25.1336C12.2233 25.3177 11.9131 25.4766 11.5891 25.6075C10.5295 26.0357 9.36846 26.1474 8.2467 25.9289C7.12494 25.7104 6.09067 25.1711 5.26929 24.3765C4.86345 23.9838 4.51819 23.5372 4.24191 23.0514C3.77165 22.2246 2.86758 21.6245 1.94042 21.8372C1.01325 22.0498 0.419834 22.9837 0.798736 23.8562C1.28513 24.9763 1.98852 25.9955 2.87413 26.8523C4.17456 28.1103 5.81206 28.9641 7.58807 29.3101C9.36408 29.656 11.2023 29.4793 12.8799 28.8013C13.9429 28.3717 14.9124 27.7525 15.744 26.9792L15.7993 27.0059L27.4696 15.5587C27.7214 15.3517 27.9909 15.165 28.2759 15.001C29.2665 14.4311 30.4012 14.1609 31.5424 14.223C32.6835 14.2852 33.7821 14.6771 34.705 15.3512C35.161 15.6843 35.5644 16.0792 35.9048 16.5224C36.4843 17.2767 37.4623 17.7468 38.3514 17.4086Z"
            fill="white"
          />
          <path
            d="M11.9216 11.1352C12.837 11.3942 13.1843 12.4447 12.7621 13.2971C12.3398 14.1495 11.303 14.4696 10.3652 14.3103C10.0261 14.2526 9.68152 14.2246 9.33534 14.227C8.41218 14.2333 7.50336 14.456 6.68181 14.8771C5.86027 15.2982 5.14888 15.906 4.60473 16.6518C4.40068 16.9315 4.22228 17.2276 4.07105 17.5366C3.65287 18.391 2.78767 19.0458 1.84912 18.8909C0.910578 18.736 0.260506 17.8407 0.584677 16.9464C0.884385 16.1196 1.30022 15.3365 1.82198 14.6214C2.68349 13.4407 3.80979 12.4783 5.11048 11.8116C6.41118 11.1449 7.85006 10.7923 9.31164 10.7823C10.1968 10.7762 11.0754 10.8958 11.9216 11.1352Z"
            fill="white"
          />
          <path
            d="M17.8259 15.6265C17.8259 16.6843 16.9684 17.5418 15.9107 17.5418C14.8529 17.5418 13.9954 16.6843 13.9954 15.6265C13.9954 14.5687 14.8529 13.7112 15.9107 13.7112C16.9684 13.7112 17.8259 14.5687 17.8259 15.6265Z"
            fill="white"
          />
          <path
            d="M28.0535 29.1733C27.1382 28.9143 26.7908 27.8638 27.213 27.0114C27.6353 26.159 28.6721 25.8389 29.6099 25.9982C29.949 26.0559 30.2936 26.0839 30.6398 26.0815C31.5629 26.0752 32.4718 25.8525 33.2933 25.4314C34.1148 25.0103 34.8262 24.4025 35.3704 23.6567C35.5744 23.377 35.7528 23.0809 35.9041 22.7719C36.3222 21.9175 37.1874 21.2627 38.126 21.4176C39.0645 21.5725 39.7146 22.4678 39.3904 23.3621C39.0907 24.1889 38.6749 24.972 38.1531 25.6871C37.2916 26.8678 36.1653 27.8302 34.8646 28.4969C33.5639 29.1636 32.1251 29.5162 30.6635 29.5262C29.7783 29.5323 28.8997 29.4127 28.0535 29.1733Z"
            fill="white"
          />
          <path
            d="M22.1494 24.683C22.1494 23.6252 23.0069 22.7677 24.0647 22.7677C25.1224 22.7677 25.9799 23.6252 25.9799 24.683C25.9799 25.7408 25.1224 26.5983 24.0647 26.5983C23.0069 26.5983 22.1494 25.7408 22.1494 24.683Z"
            fill="white"
          />
        </g>
      </svg>
    </Box>
  )
}
