import { ChatMessage } from "@livekit/components-core"
import { useChat } from "@livekit/components-react"
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  CaretDown,
  CaretUp,
  ChatCircleText,
  PaperPlaneTilt,
  X,
} from "@phosphor-icons/react"
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { ChatBox } from "./ChatBox"

type UseChatReturnType = ReturnType<typeof useChat>

interface CustomChatBoxProps extends UseChatReturnType {
  showChat: boolean
  closeChat: () => void
  openChat: () => void
}

const ChatInput = React.memo(
  ({
    text,
    setText,
    handleSend,
  }: {
    text: string
    setText: (text: string) => void
    handleSend: () => void
  }) => (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      flexDirection={"row"}
      height={{ xs: 40, sm: 50 }}
      sx={{ backgroundColor: "#F5F5F7" }}
      borderRadius={"1.5rem"}
      padding={{ xs: "0.75rem", sm: "1rem" }}
      gap={1}
    >
      <input
        type="text"
        style={{
          width: "80%",
          height: "100%",
          backgroundColor: "#F5F5F7",
          border: "none",
          color: "black",
          outline: "none",
          fontSize: "inherit",
        }}
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSend()
          }
        }}
        placeholder="Send a message"
        key={"chat-input"}
        name={"chat-input"}
        autoComplete="off"
        autoFocus
      />
      <Box
        sx={{ cursor: "pointer", aspectRatio: 1, backgroundColor: "#4019B6" }}
        width={{ xs: 24, sm: 30 }}
        height={{ xs: 24, sm: 30 }}
        borderRadius={"1.5rem"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        onClick={handleSend}
      >
        <PaperPlaneTilt color="white" size={20} />
      </Box>
    </Box>
  ),
)

const ChatMessages = React.memo(
  ({
    chatMessages,
    chatContainerRef,
    messagesEndRef,
  }: {
    chatMessages: ChatMessage[]
    chatContainerRef: React.RefObject<HTMLDivElement>
    messagesEndRef: React.RefObject<HTMLDivElement>
  }) => (
    <Box
      ref={chatContainerRef}
      display={"flex"}
      flexDirection={"column"}
      flex={1}
      overflow={"auto"}
      gap={1.5}
      marginY={1}
      sx={{
        "&::-webkit-scrollbar": {
          width: "0.5rem",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#F5F5F7",
          borderRadius: "0.25rem",
        },
        maxHeight: "calc(100% - 120px)",
      }}
    >
      {chatMessages.map((message) => (
        <ChatBox key={message.id} item={message} />
      ))}
      <div ref={messagesEndRef} />
    </Box>
  ),
)

const ChatBoxMobile = React.memo(
  ({
    showChat,
    closeChat,
    openChat,
    chatMessages,
    chatContainerRef,
    messagesEndRef,
    text,
    setText,
    handleSend,
  }: {
    showChat: boolean
    closeChat: () => void
    openChat: () => void
    chatMessages: ChatMessage[]
    chatContainerRef: React.RefObject<HTMLDivElement>
    messagesEndRef: React.RefObject<HTMLDivElement>
    text: string
    setText: (text: string) => void
    handleSend: () => void
  }) => (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        height: showChat ? "50vh" : "5rem",
      }}
      position={"absolute"}
      right={showChat ? "1rem" : "0%"}
      left={showChat ? "1rem" : "0%"}
      bottom={showChat ? "1rem" : "0%"}
      zIndex={1000}
      borderRadius={1}
      padding={"1rem"}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={"row"}
        width={"100%"}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Typography
            variant="body1"
            color="black"
            fontWeight={600}
            fontSize={"0.9rem"}
          >
            Conversation
          </Typography>
          <IconButton onClick={showChat ? closeChat : openChat}>
            {showChat ? (
              <CaretDown color="black" size={18} />
            ) : (
              <CaretUp color="black" size={18} />
            )}
          </IconButton>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          width={40}
          height={40}
          onClick={closeChat}
          sx={{ cursor: "pointer" }}
        >
          <ChatCircleText color="black" size={24} />
        </Box>
      </Box>
      {showChat && (
        <Fragment>
          <ChatMessages
            chatMessages={chatMessages}
            chatContainerRef={chatContainerRef}
            messagesEndRef={messagesEndRef}
          />
          <ChatInput text={text} setText={setText} handleSend={handleSend} />
        </Fragment>
      )}
    </Box>
  ),
)

const ChatBoxPC = React.memo(
  ({
    showChat,
    closeChat,
    chatMessages,
    chatContainerRef,
    messagesEndRef,
    text,
    setText,
    handleSend,
  }: {
    showChat: boolean
    closeChat: () => void
    openChat: () => void
    chatMessages: ChatMessage[]
    chatContainerRef: React.RefObject<HTMLDivElement>
    messagesEndRef: React.RefObject<HTMLDivElement>
    text: string
    setText: (text: string) => void
    handleSend: () => void
  }) => {
    if (!showChat) return null
    return (
      <Box
        sx={{ backgroundColor: "white" }}
        display={"flex"}
        flexDirection={"column"}
        borderRadius={"12px"}
        padding={"1rem"}
        width={"32%"}
        position={"relative"}
        height={"100%"}
        ml={2}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={"row"}
          width={"100%"}
        >
          <Typography
            variant="body1"
            color="black"
            fontWeight={600}
            fontSize={{ xs: "0.875rem", sm: "1rem" }}
          >
            Conversation
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            width={40}
            height={40}
            onClick={closeChat}
            sx={{ cursor: "pointer" }}
          >
            <X color="black" size={24} />
          </Box>
        </Box>
        <ChatMessages
          chatMessages={chatMessages}
          chatContainerRef={chatContainerRef}
          messagesEndRef={messagesEndRef}
        />
        <ChatInput text={text} setText={setText} handleSend={handleSend} />
      </Box>
    )
  },
)

const CustomChatBoxComponent = ({
  showChat,
  closeChat,
  openChat,
  chatMessages,
  isSending,
  send,
  update,
}: CustomChatBoxProps) => {
  const [text, setText] = useState("")
  const [isNearBottom, setIsNearBottom] = useState(true)

  const messagesEndRef = useRef<HTMLDivElement>(null)
  const chatContainerRef = useRef<HTMLDivElement>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const checkIfNearBottom = useCallback(() => {
    const container = chatContainerRef.current
    if (!container) return true

    const threshold = 150
    return (
      container.scrollHeight - container.scrollTop - container.clientHeight <
      threshold
    )
  }, [])

  const scrollToBottom = useCallback(
    (behavior: ScrollBehavior = "auto") => {
      if (isNearBottom) {
        messagesEndRef.current?.scrollIntoView({ behavior })
      }
    },
    [isNearBottom],
  )

  const handleScroll = useCallback(() => {
    setIsNearBottom(checkIfNearBottom())
  }, [checkIfNearBottom])

  useEffect(() => {
    const container = chatContainerRef.current
    if (container) {
      container.addEventListener("scroll", handleScroll)
      return () => container.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    scrollToBottom("smooth")
  }, [chatMessages.length, scrollToBottom])

  useEffect(() => {
    if (showChat) {
      scrollToBottom()
    }
  }, [showChat, scrollToBottom])

  const handleSend = useCallback(() => {
    send(text)
    setText("")
    setIsNearBottom(true)
    setTimeout(() => scrollToBottom("smooth"), 100)
  }, [text, send, scrollToBottom])

  const Component = isMobile ? ChatBoxMobile : ChatBoxPC

  return (
    <Component
      showChat={showChat}
      closeChat={closeChat}
      openChat={openChat}
      chatMessages={chatMessages}
      chatContainerRef={chatContainerRef}
      messagesEndRef={messagesEndRef}
      text={text}
      setText={setText}
      handleSend={handleSend}
    />
  )
}

export const CustomChatBox = React.memo(
  CustomChatBoxComponent,
  (prevProps, nextProps) => {
    return (
      prevProps.showChat === nextProps.showChat &&
      prevProps.isSending === nextProps.isSending &&
      prevProps.chatMessages === nextProps.chatMessages
    )
  },
)
